<template>

<div class="bg-default">
    <header class="header">
        <div class="header-left">
            <a href="#">
                <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
            </a>
        </div>
        <div class="header-center">
        </div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="card card-800 justify-center card-centerred">
                <CircleCountdown @end="goNext" :time="$config.settings.waitingTime"/>
                <div class="card-info">
                    A kísérlet néhány másodperc múlva indul!
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
    </footer>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CircleCountdown from '../components/CircleCountdown2.vue';
export default {
    components: {
        CircleCountdown,
    },
    computed: {
        ...mapGetters([
            'status',
            'groupId',
            'stopGameTurnId',
            'actualGameTurnId',
        ]),
    },
    methods: {
        goNext() {
            if(this.groupId) {
                if(this.actualGameTurnId === this.stopGameTurnId) {
                    return this.$router.push('group-welcome');
                    }
                    return this.$router.push('welcome');
            }
            this.$router.push('video');
        }
    },
    watch: {
        status(newStatus,oldStatus) {
            
        }
    }
}
</script>

<style>

</style>
